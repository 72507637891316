import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Loader from './components/Loader/Loader';  // Import the Loader component

// Lazy load components
const Home = lazy(() => import('./containers/Home/Home'));
const About = lazy(() => import('./containers/About/About'));
const Service = lazy(() => import('./containers/Service/Service'));
const Contact = lazy(() => import('./containers/Contact/Contact'));
const Gallery = lazy(() => import('./containers/Gallery/Gallery'));

function App() {
  return (
    <div className="App">
      {/* Wrap the Routes inside Suspense with a fallback loader */}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/service/:serviceName' element={<Service />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/gallery' element={<Gallery />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
